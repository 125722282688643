<template>
    <aside>
      <div class="logo"><router-link to="/"><img src="@/assets/images/logo.png"/></router-link></div>
      <nav class="nav" v-if="step <= 17">
          <ul>
          <li><a href="#" v-bind:class="{ done : step > 2 , current : step == 1 || step == 2 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Tatvorwurf</a></li>
          <li><a href="#" v-bind:class="{ done : step > 3 , current : step == 3 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Rechtsschutzversicherung</a></li>
          <li><a href="#" v-bind:class="{ done : step > 4 , current : step == 4 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Post von Behörde</a></li>
          <li><a href="#" v-bind:class="{ done : step > 11 , current : step == 5 || step == 6 || step == 7 || step == 8 || step == 9 || step == 10 || step == 11 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Details</a></li>
          <li><a href="#" v-bind:class="{ done : step > 12 , current : step == 12 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Daten</a></li>
          <li><a href="#" v-bind:class="{ done : step > 13 , current : step == 13 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Kostenlose Auswertung</a></li>
          </ul>
      </nav>
      <nav class="nav" v-if="step >= 13">
          <ul>
          <li><a class="current" href="#">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>Fallnummer {{user.casenumber}} {{user.firstname}} {{user.name}} {{user.accusation}}</a></li>
          </ul>
      </nav>
      <div class="info">
          <p><a href="#" @click="showHotlineModal(!modalHotline)">
              <svg>
              <use xlink:href="@/assets/images/sprite.svg#icons8-ringer-volume"></use>
              </svg>Kostenlose Hotline</a></p>
          <p class="bottom-link"><span><router-link to="/impressum"><svg>
                  <use xlink:href="@/assets/images/sprite.svg#new-window"></use>
              </svg>Impressum</router-link>
              </span><span><router-link to="/datenschutz">Datenschutz</router-link></span></p>
      </div>
    </aside>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AsideV3',

  methods: {
    ...mapMutations({
        showHotlineModal: 'setModalHotline'
      }),
  },
  computed: mapState({
    step: (state) => state.step,
    user: (state) => state.user,
    modalHotline: (state) => state.modalHotline
  }),
}
</script>